import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`CrossFit Open WOD 18.2`}</strong></p>
    <p>{`1-2-3-4-5-6-7-8-9-10 reps for time of:\\
Dumbbell squats\\
Bar-facing burpees`}</p>
    <p><em parentName="p">{`Men use 50-lb. dumbbells, Women use 35-lb. dumbbells.`}</em></p>
    <p><strong parentName="p">{`Workout 18.2a`}</strong></p>
    <p>{`1-rep-max clean`}</p>
    <p><strong parentName="p">{`Time cap:`}</strong>{` 12 minutes to complete 18.2 `}<strong parentName="p">{`AND`}</strong>{` 18.2a`}</p>
    <p><strong parentName="p">{`*`}{`We will be judging 18.2 today during our 9:00 & 10:00 classes and
also from 11-12.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      